export default {
  API_URL: process.env.API_BASE_URL || process.env.REACT_APP_API_BASE_URL,
  AWS_AUTH_REGION: process.env.AWS_AUTH_REGION || process.env.REACT_APP_AWS_AUTH_REGION,
  AWS_AUTH_USER_POOL_ID: process.env.AWS_AUTH_USER_POOL_ID || process.env.REACT_APP_AWS_AUTH_USER_POOL_ID,
  AWS_AUTH_USER_POOL_WEBCLIENT_ID: process.env.AWS_AUTH_USER_POOL_WEBCLIENT_ID || process.env.REACT_APP_AWS_AUTH_USER_POOL_WEBCLIENT_ID,
  GOOGLE_API_KEY: process.env.GOOGLE_API_KEY || process.env.REACT_APP_GOOGLE_API_KEY,
  GOOGLE_ANALYTICS_TRACK_ID: process.env.GOOGLE_ANALYTICS_TRACK_ID || process.env.REACT_APP_GOOGLE_ANALYTICS_TRACK_ID,
  ANDROID_INTENT_PACKAGE: process.env.ANDROID_INTENT_PACKAGE || process.env.REACT_APP_ANDROID_INTENT_PACKAGE,
  POLICY_BASE_URL: process.env.POLICY_BASE_URL || process.env.REACT_APP_POLICY_BASE_URL,
  IOS_DEEPLINK_DESTINATION: process.env.IOS_DEEPLINK_DESTINATION || process.env.REACT_APP_IOS_DEEPLINK_DESTINATION,
  SUBSCRIPTION_RUI_IVASS_URL: process.env.SUBSCRIPTION_RUI_IVASS_URL || process.env.REACT_APP_SUBSCRIPTION_RUI_IVASS_URL,
  FIT2YOU_BROKER_URL: process.env.FIT2YOU_BROKER_URL || process.env.REACT_APP_FIT2YOU_BROKER_URL,
  FIT2YOU_INSURANCE_OFFER_URL: process.env.FIT2YOU_INSURANCE_OFFER_URL || process.env.REACT_APP_FIT2YOU_INSURANCE_OFFER_URL,
};
