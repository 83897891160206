import React, { Component, Fragment } from "react";
import './Footer.css'
import K from '../../../constants'

export default class Footer extends Component {
  render() {
    return (
      <Fragment>
        <div className={'FooterSpacer'}/>
        <p className={'Footer'}>
            <span>AIR S.r.l. N. di iscrizione al R.U.I. E000763198, con riferimento all’attività di distribuzione assicurativa opera in qualità di collaboratore dell’intermediario Fit2You
              <br/>
              Broker S.r.l. N. di iscrizione al R.U.I. B000425709 ed è soggetta alla vigilanza IVASS. L’iscrizione è consultabile sul sito Ivass – <a
                className={'FooterLink'}
                href={K.SUBSCRIPTION_RUI_IVASS_URL}>Ricerca nel RUI</a>
            </span>
          <br/>
          <br/>
          <span>L’informativa privacy generale di Fit2You Broker S.r.l. è consultabile <a className={'FooterLink'}
                                                                                          href={K.FIT2YOU_BROKER_URL}>cliccando qui</a>.</span>
        </p>
      </Fragment>
    )
  }
}
