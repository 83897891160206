import React from 'react';

const SvgElephant = ({svgRef, title, ...props}) => (
    <svg viewBox="0 0 23 29" fill="none" ref={svgRef} xmlns="http://www.w3.org/2000/svg" {...{...{width: '1em', height: '1em'}, ...props}}>
    <path
        d="M17.6641 2.18457L17.6902 2.20747L17.7442 2.23854L17.7197 2.21728C18.6544 2.73479 19.5265 3.35823 20.3186 4.07529C19.5104 3.34386 18.6195 2.70932 17.6641 2.18457Z"
        fill="#F6B298"/>
    <path d="M21.7646 14.1044C21.7772 14.0889 21.7876 14.0719 21.7957 14.0537L21.7646 14.1044Z" fill="#ED7656"/>
    <path d="M0.0147201 20.1064C0.00779175 20.1221 0.00284686 20.1386 0 20.1555L0.0147201 20.1064Z" fill="#ED7656"/>
    <path
        d="M6.67353 28.4474C5.96696 24.9816 6.75204 22.669 13.3336 19.1885C14.2836 18.687 15.1945 18.1146 16.0584 17.476L16.0453 17.4957C16.0453 17.4957 20.2978 16.529 21.7698 14.1051C21.6422 14.2687 21.0403 14.8231 18.4676 15.0031C19.3672 14.0969 20.2438 13.1581 20.9209 12.059C21.3136 11.3458 21.6299 10.593 21.8647 9.8134C22.0416 9.11525 22.1295 8.39755 22.1264 7.67735V7.6381C22.1001 7.23949 22.0344 6.84447 21.9301 6.45885C21.0992 3.94662 17.8952 2.28979 16.3937 1.51781C14.8923 0.745818 13.9927 0.34674 13.9224 0.0261691L13.8766 0H11.6964C12.5305 1.18906 13.9485 1.93978 14.9675 2.57765C18.5412 4.82001 19.5373 7.11472 19.5373 8.16311C19.5373 9.21151 19.2576 12.36 10.7592 12.36C7.68758 12.36 5.50901 13.5752 4.14494 15.2108L4.15639 15.1715C4.15639 15.1715 0.684084 17.7933 0.0151367 20.106C0.082195 19.931 0.474731 19.2768 2.722 17.9454C2.47026 18.8273 2.34363 19.7401 2.34582 20.6572C2.36931 23.311 2.82904 25.9428 3.70661 28.4474H6.67353Z"
        fill="#ED7656"/>
    <path
        d="M17.7114 2.21777L17.736 2.23904C18.5135 2.6794 19.2498 3.18904 19.9358 3.76175C20.0634 3.86643 20.1877 3.97274 20.3103 4.08886C19.5182 3.37181 18.6462 2.74836 17.7114 2.23086"
        fill="#E85339"/>
    <path
        d="M17.7363 2.23926L17.751 2.25234C18.5124 2.70606 19.2424 3.2104 19.9362 3.76197C19.2499 3.19209 18.5136 2.6852 17.7363 2.24744"
        fill="#E53A28"/>
    <path
        d="M15.0734 18.9336C14.0397 20.296 12.3584 22.3568 11.148 22.89C11.0156 22.9489 10.8863 22.9963 10.7637 23.0372C10.7637 25.3777 11.5815 28.4477 11.5815 28.4477H14.8853C14.8853 28.4477 14.1117 27.5154 14.1117 23.0601C14.1117 21.6405 14.4911 19.9705 15.093 18.9336"
        fill="#ED7657"/>
    <path
        d="M11.179 20.4125C10.1863 21.0033 9.2573 21.695 8.40674 22.4766C8.57566 22.7062 8.798 22.8911 9.05449 23.0153C9.31099 23.1396 9.59391 23.1995 9.87875 23.1897C10.1793 23.1857 10.4774 23.1344 10.762 23.0376V23.0033C10.7686 22.123 10.9115 21.2491 11.1856 20.4125H11.179ZM15.4446 11.3629C15.3432 11.3633 15.244 11.3335 15.1596 11.2773C15.0751 11.2212 15.0092 11.1413 14.9703 11.0477C14.9313 10.9541 14.9211 10.851 14.9408 10.7515C14.9605 10.6521 15.0093 10.5607 15.081 10.489C15.1526 10.4173 15.244 10.3685 15.3435 10.3488C15.4429 10.3291 15.546 10.3394 15.6396 10.3784C15.7332 10.4173 15.8132 10.4832 15.8693 10.5676C15.9254 10.6521 15.9552 10.7513 15.9549 10.8526C15.9549 10.9197 15.9417 10.986 15.916 11.0479C15.8904 11.1098 15.8528 11.1661 15.8054 11.2135C15.758 11.2609 15.7018 11.2985 15.6399 11.3241C15.5779 11.3497 15.5116 11.3629 15.4446 11.3629ZM18.3412 5.48635C18.3019 9.16147 13.7616 9.8664 10.6327 12.3557H10.7554C19.2604 12.3557 19.5335 9.21054 19.5335 8.15887C19.5335 7.57661 19.226 6.60999 18.3412 5.48145"
        fill="#F29577"/>
    <path
        d="M17.6953 2.21777C17.7231 2.97504 18.3495 4.53537 18.3495 5.43657V5.48564C19.2343 6.61418 19.5418 7.5808 19.5418 8.16306C19.5418 9.21473 19.2621 12.3599 10.7637 12.3599H10.6313C8.97442 13.6782 7.7085 15.4986 7.7085 18.5227C7.7085 20.0389 7.76411 21.4668 8.35127 22.3909C8.36782 22.4196 8.38583 22.4474 8.40525 22.4743C9.2564 21.6931 10.186 21.0019 11.1792 20.4118C11.804 18.4606 13.2073 16.2771 15.9223 14.4878C19.9458 11.8382 20.7979 9.76264 20.7979 7.44177C20.7979 4.62206 18.7289 3.10261 17.7492 2.25212L17.6936 2.21777"
        fill="#E64830"/>
    <path
        d="M16.1265 17.4758H16.1036C16.0333 17.5608 15.9646 17.6393 15.8959 17.7325C15.5863 18.0894 15.317 18.4794 15.0929 18.8954L15.0732 18.9281L15.1599 18.8137L16.0938 17.5052C16.1038 17.4909 16.1148 17.4772 16.1265 17.4643V17.4758ZM20.312 4.06738C20.9663 4.65231 21.4878 5.37063 21.8412 6.17399C21.8952 6.30721 21.9421 6.44314 21.9819 6.58125C22.0406 6.8068 22.0859 7.03564 22.1176 7.26655C22.1314 7.3975 22.1385 7.52906 22.1389 7.66072V7.68362V7.69671V7.74904C22.1359 8.11996 22.1097 8.49032 22.0604 8.85796V8.88249V8.92992V8.94464C22.0213 9.23089 21.9667 9.51481 21.8968 9.79514C21.3113 11.9966 19.926 13.5439 18.4818 15.0011H18.5014C20.3839 13.1055 22.1618 11.0594 22.1618 7.67217C22.1618 6.18381 21.344 5.01438 20.3169 4.07392"
        fill="#F29577"/>
    <path
        d="M22.046 8.96747C22.0077 9.25439 21.9531 9.53889 21.8824 9.8196C21.9523 9.53927 22.0069 9.25535 22.046 8.96911V8.96747ZM20.2959 4.08203C20.9508 4.66809 21.4728 5.38748 21.8268 6.19191C21.4733 5.38855 20.9519 4.67023 20.2975 4.0853"
        fill="#EB684A"/>
    <path
        d="M18.4963 15.001H18.4816C17.6638 15.8188 16.8297 16.6186 16.1084 17.4805H16.1313C16.8509 16.6186 17.6867 15.8269 18.5012 15.0059"
        fill="#E64830"/>
    <path
        d="M16.1042 17.4812L16.0453 17.4959C15.1895 18.1271 14.2874 18.6929 13.3466 19.1887C12.8396 19.4569 12.3609 19.7192 11.9106 19.9754C11.6571 20.1226 11.4199 20.2682 11.1795 20.4121C10.9054 21.2486 10.7625 22.1226 10.7559 23.0028V23.0372C10.8785 22.9963 11.0077 22.9489 11.1402 22.89C12.3587 22.3568 14.0401 20.296 15.0656 18.9335L15.0852 18.9008C15.3093 18.4848 15.5786 18.0948 15.8883 17.7379C15.957 17.6513 16.0257 17.5744 16.096 17.4812H16.1042ZM21.8744 9.81362C21.6363 10.5886 21.3172 11.3363 20.9225 12.0445C20.2536 13.1485 19.3769 14.089 18.4692 14.9886C19.9183 13.5313 21.3036 11.984 21.8663 9.80708L21.8744 9.81362ZM22.0396 8.86663V8.89116V8.86663ZM22.1181 7.75772C22.1151 8.12863 22.0889 8.49899 22.0396 8.86663C22.0889 8.49899 22.1151 8.12863 22.1181 7.75772ZM21.8205 6.17285C21.845 6.24645 21.8679 6.31842 21.8892 6.39365L21.9072 6.44599C21.9284 6.52613 21.9497 6.60955 21.9677 6.68969C22.0439 7.01227 22.0942 7.34043 22.1181 7.67103C22.1177 7.53937 22.1106 7.40781 22.0969 7.27686C22.0652 7.04595 22.0199 6.81711 21.9611 6.59156C21.9214 6.45345 21.8744 6.31751 21.8205 6.1843"
        fill="#E64830"/>
    <path
        d="M16.058 17.476C15.194 18.1145 14.2832 18.6869 13.3331 19.1884C12.8244 19.4566 12.3518 19.7183 11.9102 19.9751C12.3616 19.7189 12.8402 19.4566 13.3462 19.1884C14.2873 18.6943 15.19 18.1301 16.0465 17.5005L16.058 17.4809V17.476ZM21.9885 6.70576C22.0576 7.01369 22.1029 7.32645 22.1243 7.64131V7.67565C22.1281 8.39633 22.0407 9.11459 21.8642 9.81334C21.6253 10.5935 21.3046 11.3462 20.9074 12.059C20.2385 13.163 19.3618 14.1018 18.4541 15.003H18.4819C19.3814 14.0953 20.2581 13.1548 20.9352 12.059C21.3263 11.3456 21.641 10.5928 21.874 9.81334C21.9447 9.53263 21.9993 9.24813 22.0376 8.96121V8.94649V8.89906V8.87452C22.0869 8.50688 22.1131 8.13652 22.1161 7.76561V7.71327V7.70346V7.6871C22.0922 7.3565 22.0419 7.02834 21.9656 6.70576H21.9885ZM19.9359 3.76174C20.8027 4.49938 21.5567 5.38422 21.91 6.41463C21.8887 6.3394 21.8658 6.26743 21.8413 6.19383C21.4873 5.3894 20.9654 4.67001 20.3104 4.08395C20.1878 3.97273 20.0635 3.86642 19.9359 3.75684"
        fill="#E33424"/>
    <path
        d="M17.7507 2.25195C18.7321 3.10245 20.7994 4.62189 20.7994 7.44161C20.7994 9.76248 19.9473 11.838 15.9238 14.4876C13.2071 16.2769 11.8038 18.4604 11.1807 20.4116C11.4129 20.2677 11.6582 20.1222 11.9118 19.975C12.3534 19.7182 12.826 19.4565 13.3347 19.1882C14.2848 18.6868 15.1956 18.1143 16.0596 17.4758L16.0481 17.4954L16.107 17.4807C16.8283 16.6188 17.664 15.8239 18.4802 15.0012H18.4655C19.365 14.0951 20.2417 13.1563 20.9188 12.0572C21.3119 11.3445 21.6288 10.5923 21.8642 9.81318C22.0411 9.11503 22.129 8.39733 22.1259 7.67713V7.63787C22.1046 7.32302 22.0592 7.01026 21.9901 6.70233C21.9721 6.62055 21.9509 6.53877 21.9296 6.45863L21.9116 6.40629C21.5583 5.37589 20.8043 4.49104 19.9375 3.7534C19.2435 3.20464 18.5135 2.70303 17.7524 2.25195"
        fill="#E2271D"/>
</svg>);

const Elephant = React.forwardRef((props, ref) => <SvgElephant svgRef={ref} {...props} />);
export default Elephant;

