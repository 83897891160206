import React, { useState } from 'react';
import { addDays, format } from 'date-fns'
import { inject } from "mobx-react";

import { CheckmarkSquareIcon, ChevronDownIcon, ChevronUpIcon } from "../../icons";
import MyPageButton from "../../components/MyPageButton";

import "./ProductCard.css"

const isMoVeIn = product => product.features.some(feature => feature.node.name === 'movein');

const isAirFeatured = product => product.features.some(feature => feature.node.name === 'air');

const isExpiring = (subscription) => {
  return subscription && subscription.node && new Date(subscription.node.end_date) < addDays(new Date(), 30);
};

const isExpired = (subscription) => {
  return subscription && subscription.node && new Date(subscription.node.end_date) < new Date();
};

const isGrayed = (subscription) =>
  subscription && !isExpiring(subscription)

const getProductPrice = (product) =>
  Number.parseFloat(product.productRatePlans[0].productRatePlanCharges.reduce(
    (acc, currentCharge) => acc + currentCharge.pricing[0].price,
    0
  )).toFixed(2)

const FrontAvailable = inject('translationsStore')(({
                                                      product,
                                                      showPrice,
                                                      logo,
                                                      description,
                                                      subscription,
                                                      onSelect,
                                                      translationsStore,
                                                      disabled,
                                                      isDiscounted,
                                                      variant
                                                    }) => {

  let media = <img alt="product" src={`products/${logo}.png`}/>; // TODO check variant prop to improve media rendering to show, reducing number of file assets
  return (
    <React.Fragment>
      <div>
        {isDiscounted && <div
          className={'DiscountedBadge'}>{translationsStore.translate('servicesView.serviceCard.discount.sale')}</div>}
        {media}
      </div>
      <div className="CardContent Available">
        <div className="TextContainer">
          <p className="Description">{description}</p>
        </div>
        {showPrice && <div className="Price">
          {isDiscounted ?
            <div
              className='PromoPrice'>{translationsStore.translate('servicesView.serviceCard.discount.promoPrice')}</div>
            : <div
              className='PriceLabel'> {translationsStore.translate('servicesView.serviceCard.price')} {getProductPrice(product)}{translationsStore.translate('servicesView.serviceCard.currencyPlusTax')}</div>}
        </div>}
        <MyPageButton
          theme="primary"
          solid
          title={
            product.features.some(feature => feature.node.name === 'movein' && product.category === 'base')
              ? translationsStore.translate('servicesView.serviceCard.blackBoxSubmitButton')
              : translationsStore.translate('servicesView.serviceCard.submitButton')
          }
          onClick={() => onSelect(product)}
          disabled={disabled}
        />
      </div>
    </React.Fragment>
  );
});

const FrontSubscribed = inject('translationsStore')(({
                                                       product,
                                                       logo,
                                                       description,
                                                       subscription,
                                                       onSelect,
                                                       translationsStore,
                                                       variant
                                                     }) => {

  let media = <img alt="product" src={`products/${logo}_expired.png`}/>; // TODO check variant prop to improve media rendering to show, reducing number of file assets
  return (
    <React.Fragment>
      {media}
      <div className="CardContent">
        <div className="TextContainer">
        </div>
        <div>
          <p className="ActiveLabel">Attivo</p>
          <p className="Price">Scade il {format(subscription.node.end_date, "DD/MM/YYYY")}</p>
        </div>
      </div>
    </React.Fragment>
  );
});

const FrontExpiring = inject('translationsStore')(({
                                                     product,
                                                     logo,
                                                     renewDisabled,
                                                     subscription,
                                                     onSelect,
                                                     translationsStore,
                                                     isDiscounted,
                                                     variant
                                                   }) => {
  let media = <img alt="product" src={`products/${logo}.png`}/>; // TODO check variant prop to improve media rendering to show, reducing number of file assets
  return (
    <React.Fragment>
      <div>
        {isDiscounted && <div
          className={'DiscountedBadge'}>{translationsStore.translate('servicesView.serviceCard.discount.sale')}</div>}
        {media}
      </div>
      <div className="CardContent Expiring">
        <div className="TextContainer">
        </div>
        <div>
          {!isExpired(subscription) && <p className="ActiveLabel">Attivo</p>}
          <p className="ExpiringLabel">{isExpired(subscription) ? "Scaduto" : "In scadenza"}</p>
          <div className="Price">
            {isDiscounted ?
              <div
                className='PromoPrice'>{translationsStore.translate('servicesView.serviceCard.discount.promoPrice')}</div>
              : isAirFeatured(product) ?
                <div className='PriceLabel'>{translationsStore.translate('servicesView.serviceCard.price')} 120€
                  anno <br/>o 12€ mese</div> : <div
                  className='PriceLabel'> {translationsStore.translate('servicesView.serviceCard.price')} {getProductPrice(product)}{translationsStore.translate('servicesView.serviceCard.currencyPlusTax')}</div>}
            <div>{format(subscription.node.end_date, "DD/MM/YYYY")}</div>
          </div>
        </div>
        <MyPageButton
          theme="primary"
          solid
          disabled={renewDisabled}
          title={"Procedi"}
          onClick={() => onSelect(product)}
        />
      </div>
    </React.Fragment>
  );
});

const CardFront = ({ subscription, ...otherProps }) => (
  isExpiring(subscription)
    ? <FrontExpiring subscription={subscription} {...otherProps} />
    : isGrayed(subscription)
      ? <FrontSubscribed subscription={subscription} {...otherProps} />
      : <FrontAvailable subscription={subscription} {...otherProps} />
);

const CardBack = inject('translationsStore')(({
                                                product,
                                                subscription,
                                                onSelect,
                                                isFront,
                                                onFlip,
                                                translationsStore
                                              }) => (
  <div className={isFront ? "CardBack closed" : "CardBack"}>
    <div className="CardBackHeader" onClick={onFlip}>
      + info {
      isFront
        ? <ChevronDownIcon className="BackIcon" width={12} height={12}/>
        : <ChevronUpIcon className="BackIcon" width={12} height={12}/>
    }

    </div>
    <img
      alt="product"
      src={`products/air_head${isGrayed(subscription) ? '_expired' : ''}.png`}
    />
    <div className="CardContent">
      <div className="TextContainer">
        {
          product.features
            .map(feature => translationsStore.translate('servicesView.serviceCard.features.' + feature.node.name))
            .flat()
            .map((feature, index) =>
              <div key={index} className="ListItemContainer">
                <div className="ListIcon"><CheckmarkSquareIcon width={16} height={16}/></div>
                <p className="Description">{feature}</p>
              </div>
            )
        }
      </div>
    </div>
  </div>
));

const ProductCardBase = ({ subscriptions, ...otherProps }) => {
  const [isFront, setIsFront] = useState(true);

  const subscription = !!subscriptions && subscriptions.find(sub => sub.product.uuid === otherProps.product.uuid);

  return (
    <div className="ProductCard">
      <CardFront {...otherProps} subscription={subscription}/>
      <CardBack {...otherProps} subscription={subscription} isFront={isFront} onFlip={() => setIsFront(!isFront)}/>
    </div>
  )
};

export default ProductCardBase;
