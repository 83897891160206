import React, { Component } from 'react';
import './Loader.css'
import './ProductCard.css'
import './InsuranceCard.css'
import { format } from "date-fns";
import MyPageButton from "../MyPageButton";
import AppBrand from "../AppBrand";
import Elephant from "../AppBrand/Elephant";
import { inject, observer } from "mobx-react";
import K from '../../../constants'
import { api } from "../../../services";
import { message } from 'antd'

@inject('translationsStore', 'mypageStore', 'authStore')
@observer
export class InsuranceCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadingToken: false
    };
  }

  render() {
    const { insurance, translationsStore, authStore, mypageStore } = this.props

    let button = <MyPageButton theme="salmon" solid title={translationsStore.translate('insuranceCard.buttonLabel')}
                               onClick={async () => {
                                 const {
                                   attributes: { 'custom:external_uuid': userUuid }
                                 } = authStore.user ? authStore.user : {};

                                 try {
                                   this.setState(prev => ({ ...prev, loadingToken: true }));
                                   const data = await api('getAgendaInsuranceToken', userUuid, mypageStore.selectedManageableVehicle.uuid);
                                   if (data.token === undefined) {
                                     throw new Error('token is undefined')
                                   }
                                   window.location.href = `${K.FIT2YOU_INSURANCE_OFFER_URL}/guest-login?token=${data.token}`
                                 } catch (error) {
                                   message.error(translationsStore.translate('InsuranceBanner.error.message'))
                                   console.error(error)
                                 } finally {
                                   this.setState(prev => ({ ...prev, loadingToken: false }));
                                 }
                               }}
                               style={{ width: 220, height: 32 }}/>

    return (
      <div className="ProductCard">
        <img alt="product" src={`products/rca_insurance_head.png`}/>
        <div className="InsuranceCardContent">
          <div className="LogoContainer">
            <AppBrand style={{ width: 63, height: 36, color: 'var(--action-brand-color)' }}/>
            <Elephant style={{ width: 37, height: 48 }}/>
          </div>
          <div className="TextContainer InsuranceTextContainer">
            <p className="">{translationsStore.translate('insuranceCard.title')}</p>
          </div>
          <div>
            <p className="ActiveLabel">{translationsStore.translate('insuranceCard.activeLabel')}</p>
            <p
              className="Price">{translationsStore.translate('insuranceCard.expiresLabel')} {format(insurance.node.date, "DD/MM/YYYY")}</p>
            {this.state.loadingToken ? <div className="loader"/> : button}
          </div>
        </div>
      </div>
    )
  }
}

export default InsuranceCard;
