import { observable, action, computed } from 'mobx';

import { authStore } from '.';
import { api } from '../services';
import moment from "moment";
import { default as _omit } from 'lodash/omit';

class MyPageStore {
  constructor({ authStore }) {
    this.authStore = authStore;
    api('currentUser').then(user => {
      this.authStore.setToken(user.signInUserSession.idToken.jwtToken);
    }).catch(err => err);
  }

  @observable
  exceptionFound = false;

  @observable
  loadingRemoteUserData = false;

  @observable
  loadingDiscounts = false;

  @observable
  remoteUserData = undefined;

  @action
  setRemoteUserData = remoteUserData => {
    this.remoteUserData = remoteUserData;
  };

  @observable
  remoteUserIsFromMoVeInProgram = false;

  @observable
  remoteUserhasSubscribedMyApp = false

  @action
  setRemoteUserIsFromMoVeInProgram = remoteUserIsFromMoVeInProgram => {
    this.remoteUserIsFromMoVeInProgram = remoteUserIsFromMoVeInProgram;
  };
  @action
  setRemoteUserhasSubscribedMyApp = remoteUserhasSubscribedMyApp => {
    this.remoteUserhasSubscribedMyApp = remoteUserhasSubscribedMyApp;
  };

  @observable
  remoteUserVehiclesRegions = [];

  @action
  setRemoteUserVehiclesRegions = remoteUserVehiclesRegions => {
    this.remoteUserVehiclesRegions = remoteUserVehiclesRegions;
  };

  @observable
  loadingManageableVehicles = false;

  @observable
  manageableVehicles = [];

  @observable
  selectedManageableVehicle = undefined;

  @observable
  userAccessToken = undefined;

  @action
  setExceptionFound = exceptionFound => {
    this.exceptionFound = exceptionFound;
  };

  @action
  setManageableVehicles = manageableVehicles => {
    this.manageableVehicles = manageableVehicles;
  };

  @action
  setSelectedManageableVehicle = manageableVehicle => {
    this.selectedManageableVehicle = manageableVehicle;
  };

  @action
  retrieveSelectedManageableVehicleDiscounts = async () => {

    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    if (this.selectedManageableVehicle && !this.selectedManageableVehicle.discounts) {
      this.loadingDiscounts = true;
      await api('getAvailableDiscounts', userUuid, this.selectedManageableVehicle.uuid)
        .then(response => {
          this.selectedManageableVehicle.discounts = response;
        })
        .catch(() => {
          this.exceptionFound = true;
        })
        .finally(() => {
          this.loadingDiscounts = false;
        });
    }
  };

  @action
  setSelectedManageableVehicleByUuid = uuid => {
    this.setSelectedManageableVehicle(this.manageableVehicles.find(vehicle => vehicle.uuid === uuid));
  };

  @observable
  visibleLeftRegion = false;

  @action
  setVisibleLeftRegion = visibleLeftRegion => {
    this.visibleLeftRegion = visibleLeftRegion;
  };

  @action
  loadUserManageableVehicles() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingManageableVehicles = true;
    return api('getUserManageableVehicles', userUuid)
      .then(response => {
        let manageableVehicles = response;
        manageableVehicles.some((val) => {
          val.subscriptions.map((val) => {
            val.product.features.some(feature => feature.node.name === 'myapp' && this.setRemoteUserhasSubscribedMyApp(true))
          })
        })
        this.setManageableVehicles(manageableVehicles);
        this.setSelectedManageableVehicle(manageableVehicles[0]);
      })
      .catch(error => {
        console.log(error);
        this.exceptionFound = true;
      })
      .then(() => {
        this.loadingManageableVehicles = false;
      });
  }

  @action
  updateUserManageableVehicles() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingManageableVehicles = true;
    return api('getUserManageableVehicles', userUuid)
      .then(response => {
        let manageableVehicles = response;
        manageableVehicles.some((val) => {
          val.subscriptions.map((val) => {
            val.product.features.some(feature => feature.node.name === 'myapp' && this.setRemoteUserhasSubscribedMyApp(true))
          })
        })
        this.setManageableVehicles(manageableVehicles);
        this.setSelectedManageableVehicle(manageableVehicles.find(vehicle => vehicle.uuid === this.selectedManageableVehicle.uuid));
      })
      .catch(error => {
        console.log(error);
        this.exceptionFound = true;
      })
      .then(() => {
        this.loadingManageableVehicles = false;
      });
  }

  @action
  loadRemoteUserData() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};
    api('currentUser').then(user => {
      this.authStore.setToken(user.signInUserSession.idToken.jwtToken);
    });
    this.loadingRemoteUserData = true;
    return api('getUserData', userUuid)
      .then(response => {
        let { managedVehicles } = response;
        managedVehicles = managedVehicles.map(v => v.node).map(v => {
          const regions = v.regions.map(r => _omit(r.node, ['_id', '_labels']));
          return { uuid: v.uuid, license_plate: v.license_plate, id_adesione: v.id_adesione, regions };
        });
        let user = response;
        this.setRemoteUserData(user);
        const remoteUserIsFromMoVeInProgram = user.hasOwnProperty('movein') && user.movein === true;
        this.setRemoteUserIsFromMoVeInProgram(remoteUserIsFromMoVeInProgram);
        this.setRemoteUserVehiclesRegions(managedVehicles);
        this.getZuoraPaymentMethods();
      })
      .catch(error => {
        console.log(error);
        this.exceptionFound = true;
      })
      .then(() => {
        this.loadingRemoteUserData = false;
      });
  }

  @action
  updateUserProfile({ city, province, street, building_number, country, postcode, ...otherData }) {
    if (!city || !province || !street || !building_number || !country || !postcode) {
      return Promise.reject('errorUpdatingUserAddressInformation');
    }
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    const userData = {
      addresses: [
        {
          type: this.remoteUserData.addresses.length > 0 ? this.remoteUserData.addresses[0].type : 'residence',
          node: { city, province, street, building_number, country, postcode }
        }
      ],
      ...otherData
    };

    if (otherData.birth_date) {
      userData.birth_date = userData.birth_date.format('YYYY-MM-DD');
    }

    this.loadingRemoteUserData = true;
    return api('updateUserProfile', userUuid, userData)
      .then(response => {
        this.setRemoteUserData(response);
      })
      .catch(error => {
        console.error(error);
        this.exceptionFound = true;
        throw error;
      })
      .finally(() => {
        this.loadingRemoteUserData = false;
      });
  }

  @observable
  loadingDocuments = false;

  @observable
  documents = undefined;

  @action
  loadDocuments() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingDocuments = true;
    return api('getDocuments', userUuid)
      .then(response => {
        this.documents = response;
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .finally(() => {
        this.loadingDocuments = false;
      });
  }

  @observable
  hasToCompleteProfile = false;

  @action
  setHasToCompleteProfile(value) {
    this.hasToCompleteProfile = value;
  }

  @action
  updateMarketingConsent(policyAccepted, marketingThirdPartiesAgreement) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingDocuments = true;
    return api('updateMarketingConsent', userUuid, this.selectedManageableVehicle.uuid, {
      id: this.selectedManageableVehicle.license_plate,
      marketing_consent_accepted: policyAccepted,
      marketing_third_parties_consent_accepted: marketingThirdPartiesAgreement,
      email: this.remoteUserData.email,
      form: document.getElementsByClassName('PrivacyUpdateForm')[0].outerHTML
    })
      .then(response => {
        this.selectedManageableVehicle.marketing_consent_accepted = policyAccepted;
        this.selectedManageableVehicle.marketing_third_parties_consent_accepted = marketingThirdPartiesAgreement;
        this.loadUserManageableVehicles();
      })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .finally(() => {
        this.loadingDocuments = false;
      });
  }

  @observable
  loadingDrivingLicense = false;

  @observable
  hasDrivingLicense = false;

  @observable
  drivingLicense = undefined;

  @action
  updateDrivingLicense(licenseData) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    return api('updateDrivingLicense', userUuid, licenseData);
  }

  @action
  createDrivingLicense(licenseData) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    return api('createDrivingLicense', userUuid, licenseData);
  }

  @action
  loadDrivingLicense() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingDrivingLicense = true;
    return api('getDrivingLicense', userUuid)
      .then(res => {
        this.drivingLicense = res;
        this.hasDrivingLicense = true;
      })
      .catch(() => {
        this.drivingLicense = undefined;
        this.hasDrivingLicense = false;
      })
      .finally(() => {
        this.loadingDrivingLicense = false;
      });
  }

  @observable
  contractGeneralConditionsName = 'condizioni-generali-movein-2024-04-12-not-flagged.pdf';

  @action
  loadContractsGeneralConditionsName() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    return api('getContractsGeneralConditionsName', userUuid, this.selectedManageableVehicle.uuid)
      .then(response => {
        if (response.length > 0) {
          this.contractGeneralConditionsName = response[0].name;
        } else {
          this.contractGeneralConditionsName = 'condizioni-generali-movein-2024-04-12-not-flagged.pdf';
        }
      })
      .catch(error => {
        console.log(error);
        throw error;
      });
  }

  @observable
  loadingUpdatingAirClubMarketing = false;

  @action
  updateAirClubMarketingConsent(airclub_general, air_club_profiling, air_club_marketing, air_club_third_parties) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    let form;
    try {

      form = document.getElementsByClassName('AirclubPrivacyForm')[0].outerHTML;
    } catch (err) {

      form = '<div/>';
    }

    this.loadingUpdatingAirClubMarketing = true;
    return api('updateAirclubMarketingConsent', userUuid, this.selectedManageableVehicle.uuid, {
      id: this.selectedManageableVehicle.license_plate,
      airclub_general,
      air_club_profiling,
      air_club_marketing,
      air_club_third_parties,
      email: this.remoteUserData.email,
      form: form
    })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .finally(() => {
        this.loadingUpdatingAirClubMarketing = false;
      });
  }

  hasVehicleReminder(vehicle) {
    if (!vehicle) {
      return false;
    }
    return vehicle.subscriptions.some(sub => sub.product.features.some(feat => feat.node.name === 'reminder'))
  }

  @computed
  get hasReminder() {
    return this.manageableVehicles.some(vehicle => this.hasVehicleReminder(vehicle));
  }

  getInstallerFromVehicle = vehicle => vehicle && vehicle.chosen_installers && vehicle.chosen_installers[0] && vehicle.chosen_installers[0].node;

  @computed
  get hasAtLeastOneVehicleWithInstaller() {
    return this.manageableVehicles.some(vehicle => this.getInstallerFromVehicle(vehicle));
  }

  hasVehicleAdditionalReminderService(vehicle) {
    return vehicle && vehicle.reminder_product_consent;
  }

  @computed
  get hasAdditionalReminderService() {
    return this.manageableVehicles.some(vehicle => this.hasVehicleAdditionalReminderService(vehicle));
  }

  @observable
  loadingZuoraPaymentMethods = false;

  @observable
  zuoraPaymentMethods = {};

  @computed
  get hasAtLeastOneSubscription() {
    return this.manageableVehicles.some(vehicle => vehicle.subscriptions && vehicle.subscriptions.length > 0);
  }

  @action
  getZuoraPaymentMethods() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingZuoraPaymentMethods = true;

    return api('getZuoraPaymentMethods', userUuid)
      .then(zuoraPaymentMethods => {

        this.zuoraPaymentMethods = zuoraPaymentMethods;
      })
      .catch(() => {

        this.exceptionFound = true;
      })
      .finally(() => {

        this.loadingZuoraPaymentMethods = false;
      })
  }

  @computed
  get defaultPaymentMethod() {

    const { defaultPaymentMethod, records } = this.zuoraPaymentMethods;

    if (defaultPaymentMethod && records) {

      return records.find(record => record.Id === defaultPaymentMethod);
    }

    return undefined;
  }

  @observable
  loadingAgenda = false;

  @action
  setLoadingAgenda = loadingAgenda => {
    this.loadingAgenda = loadingAgenda;
  };

  @observable
  agendaInsurances = [];
  @observable
  agendaMaintenances = [];
  @observable
  agendaTaxes = [];
  @observable
  selectedVehicleRCAInsurance = undefined

  @action
  loadAgenda() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.setLoadingAgenda(true);
    return Promise.all([
      api('getAgendaInsurances', userUuid),
      api('getAgendaMaintenances', userUuid),
      api('getAgendaTaxes', userUuid)
    ]).then(values => {
      this.agendaInsurances = values[0]
      this.agendaMaintenances = values[1]
      this.agendaTaxes = values[2]
    })
      .catch(error => {
        console.log(error);
        throw error;
      })
      .finally(() => {
        this.setLoadingAgenda(false);
      });
  }

  // get latest RC Auto insurance
  @action
  setSelectedVehicleLatestRCAInsurance(insuranceVehicle) {
    if (!insuranceVehicle) {
      this.selectedVehicleRCAInsurance = undefined
      return
    }

    const rcaInsurances = insuranceVehicle.insurances.filter(insurance => insurance.node.type === "RCA");
    if (rcaInsurances.length === 0) {
      this.selectedVehicleRCAInsurance = undefined
      return
    }

    this.selectedVehicleRCAInsurance = rcaInsurances.reduce((mostRecent, current) => new Date(current.node.createdAt) > new Date(mostRecent.node.createdAt) ? current : mostRecent, rcaInsurances[0]);
  }

  @action
  setSelectedVehicleLatestRCAInsuranceByUuid = uuid => {
    this.setSelectedVehicleLatestRCAInsurance(this.agendaInsurances.find(vehicle => vehicle.uuid === uuid));
  };


  @action
  async createAgendaMaintenance(vehicleUuid, km) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('createAgendaMaintenance', userUuid, vehicleUuid, {
        type: 'general',
        km: km
      })
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  async createAgendaRevisione(vehicleUuid, selectedDate) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('createAgendaMaintenance', userUuid, vehicleUuid, {
        type: 'revision',
        date: selectedDate
      })
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  async createAgendaTaxes(vehicleUuid, selectedDate) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('createAgendaTaxes', userUuid, vehicleUuid, selectedDate)
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  async createAgendaInsuranceRCA(vehicleUuid, selectedDate) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('createAgendaInsurance', userUuid, vehicleUuid, 'RCA', selectedDate)
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  async createAgendaInsuranceCVT(vehicleUuid, selectedDate) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('createAgendaInsurance', userUuid, vehicleUuid, 'CVT', selectedDate)
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  async updateAgendaMaintenance(vehicleUuid, uuid, selectedDate) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('updateAgendaMaintenance', userUuid, vehicleUuid, uuid, selectedDate)
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  async updateAgendaTaxes(vehicleUuid, uuid, selectedDate) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('updateAgendaTaxes', userUuid, vehicleUuid, uuid, selectedDate)
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  async updateAgendaInsurance(vehicleUuid, uuid, selectedDate) {
    return new Promise(async (resolve, reject) => {
      const {
        attributes: { 'custom:external_uuid': userUuid }
      } = this.authStore.user ? this.authStore.user : {};

      await api('updateAgendaInsurance', userUuid, vehicleUuid, uuid, selectedDate)
        .then(() => {
          resolve({ success: true })
        })
        .catch((e) => {
          reject({ success: false, reason: e });
        });
    });
  }

  @action
  updateAirCustomerConsent(agreement_profiling, agreement_marketing, agreement_third_parties) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};
    const currentSelectedManageableVehicleUuid = this.selectedManageableVehicle.uuid;

    this.loadingManageableVehicles = true;
    return api('updateAirCustomerConsent', userUuid, this.selectedManageableVehicle.uuid, {
      id: this.selectedManageableVehicle.license_plate,
      agreement_profiling: agreement_profiling,
      agreement_marketing: agreement_marketing,
      agreement_third_parties: agreement_third_parties,
      email: this.remoteUserData.email,
      form: document.getElementsByClassName('PrivacyUpdate')[0].outerHTML
    })
      .then(() => {
        this.loadUserManageableVehicles().then(() => {
          this.setSelectedManageableVehicleByUuid(currentSelectedManageableVehicleUuid);
        });

      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        this.loadingManageableVehicles = false;
      });
  }

  @observable
  loadingUploadDocument = false

  @action
  async uploadDocument(formData) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingUploadDocument = true;

    try {
      await api('uploadDocument', userUuid, formData);
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingUploadDocument = false;
    }
  }

  @action
  async updateDocument(documentUuid, formData) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingUploadDocument = true;

    try {
      await api('updateDocument', userUuid, documentUuid, formData);
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingUploadDocument = false;
    }
  }

  @action
  async deleteDocument(documentUuid) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingUploadDocument = true;

    try {
      await api('deleteDocument', userUuid, documentUuid);
    } catch (e) {
      console.log(e);
    } finally {
      this.loadingUploadDocument = false;
    }
  }

  @observable
  loadingIdentityCard = false;

  @observable
  hasIdentityCard = false;

  @observable
  identityCard = undefined;

  @action
  updateIdentityCard(identityCardData) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    return api('updateIdentityCard', userUuid, identityCardData);
  }

  @action
  createIdentityCard(identityCardData) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    return api('createIdentityCard', userUuid, identityCardData);
  }

  @action
  loadIdentityCard() {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};

    this.loadingIdentityCard = true;
    return api('getIdentityCard', userUuid)
      .then(res => {
        this.identityCard = res;
        this.hasIdentityCard = true;
      })
      .catch(() => {
        this.identityCard = undefined;
        this.hasIdentityCard = false;
      })
      .finally(() => {
        this.loadingIdentityCard = false;
      });
  }

  @action
  updateVehicleContactData(contactEmail, contactMobile) {
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = this.authStore.user ? this.authStore.user : {};
    const currentSelectedManageableVehicleUuid = this.selectedManageableVehicle.uuid;

    this.loadingManageableVehicles = true;
    return api('updateVehicleData', userUuid, this.selectedManageableVehicle.uuid, {
      vin: this.selectedManageableVehicle.vin,
      contact_email: contactEmail,
      contact_mobile: contactMobile,
    })
      .then(() => {
        this.loadUserManageableVehicles().then(() => {
          this.setSelectedManageableVehicleByUuid(currentSelectedManageableVehicleUuid);
        });
      })
      .catch(error => {
        throw error;
      })
      .finally(() => {
        this.loadingManageableVehicles = false;
      });
  }

  @observable
  loadingNotification = false;

  @observable
  reportNotification = undefined;

  @action
  setReportNotification = reportNotification => {
    this.reportNotification = reportNotification;
  };

  ignoreNotification = notification => {
    /*
    // TODO check if we shall handle also this step
    return new Promise((resolve, reject) => {
      resolve({ ...notification, timestamp: moment().toISOString() });
    });
    */

    const {
        attributes: { 'custom:external_uuid': userUuid },
      } = this.authStore.user ? this.authStore.user : {},
      { _id: notificationId } = notification;

    return api('archiveNotification', userUuid, notificationId).then((notification) => ({
      ...notification,
      timestamp: moment().toISOString()
    }));
  };

  snoozeNotification = (notification, snoozeRules) => {
    const {
        attributes: { 'custom:external_uuid': userUuid },
      } = this.authStore.user ? this.authStore.user : {},
      { _id: notificationId } = notification;

    return api('snoozeNotification', userUuid, notificationId, snoozeRules);
  };

  submitRequestNotification = (notification) => {
    const {
        attributes: { 'custom:external_uuid': userUuid },
      } = this.authStore.user ? this.authStore.user : {},
      { _id: notificationId } = notification;

    return api('confirmSupportRequestNotification', userUuid, notificationId).then((notification) => ({
      ...notification,
      timestamp: moment().toISOString()
    }));
  };

  filterSubscriptionAssistance(subscription) {
    return subscription.product.category === 'add-on' && subscription.product.features.some(feat => feat.node.name === 'axa-assistance') && !subscription.product.features.some(feat => feat.node.name === 'reminder');
  }

  filterSubscriptionAssistanceBase(subscription) {
    return subscription.product.category === 'add-on' && subscription.product.features.some(feat => feat.node.name === 'axa-assistance') && !subscription.product.features.some(feat => feat.node.name === 'reminder') && !subscription.product.features.some(feat => feat.node.name === 'crash-assistance');
  }

  filterSubscriptionAddOn(subscription) {
    return subscription.product.category === 'add-on';
  }

  hasAssistance(vehicle) {
    if (!vehicle) {
      return false;
    }
    return vehicle.subscriptions.some(sub => this.filterSubscriptionAssistance(sub))
  }

  hasAssistanceBase(vehicle) {
    if (!vehicle) {
      return false;
    }
    return vehicle.subscriptions.some(sub => this.filterSubscriptionAssistanceBase(sub))
  }

  hasAdditionalServiceSubscription(vehicle) {
    if (!vehicle) {
      return false;
    }
    return vehicle.subscriptions.some(sub => this.filterSubscriptionAddOn(sub))
  }
}

export default new MyPageStore({ authStore });
