import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { reaction } from 'mobx';
import { disposeOnUnmount, inject, observer } from 'mobx-react';
import { Media as BreakpointsMedia } from 'react-breakpoints';

import './ServicesView.css';
import LoadingOverlay from "react-loading-overlay";
import { PulseLoader } from "react-spinners";
import VehicleSelector from "../../../../common/components/VehicleSelector";
import MyPageRadioGroup from "../../../../common/components/MyPageRadioGroup";
import MyPageRadioButton from "../../../../common/components/MyPageRadioButton";
import ProductCardBase from "../../../../common/components/ProductCard";
import MyPageContainer from "../../../../common/components/MyPageContainer";
import { addDays } from "date-fns";
import { api } from '../../../../services';
import { isEqual, uniqBy } from 'lodash';
import moment from "moment";
import InsuranceBanner from "../../../../common/components/InsuranceBanner";
import InsuranceCard from "../../../../common/components/ProductCard/InsuranceCard";
import Footer from "../../../../common/components/Footer";

const BannerBundleDiscount = inject('translationsStore', 'productsStore')(({
                                                                             translationsStore,
                                                                             productsStore,
                                                                             bannerDiscount
                                                                           }) => {
  const discountedPercentage = productsStore.getDiscountPercentage(bannerDiscount.discountRatePlanId);

  return <React.Fragment>
    <div className="BannerBundleDiscount">
      {translationsStore.translate('servicesView.renewals.discountBanner.buyAtSameTime')}
      {bannerDiscount.bundleProductUuids.map((bundleProductUuid, index, bundleProductUuids) =>
        <Fragment key={index}>
          {' '}<span className='DiscountRed'>{productsStore.getProductNameByUuid(bundleProductUuid)}</span>
          {index < (bundleProductUuids.length - 1) ?
            <Fragment>{' '}{translationsStore.translate('servicesView.renewals.discountBanner.and')}</Fragment> : ', '}
        </Fragment>
      )
      }
      {translationsStore.translate('servicesView.renewals.discountBanner.andYouWillHaveADiscountOf')} <span
      className='DiscountRed'>
      {discountedPercentage ?
        `${discountedPercentage}%`
        : `${productsStore.getDiscountFixedAmount(bannerDiscount.discountRatePlanId)}€`
      }
      </span>!
    </div>
  </React.Fragment>
});

@withRouter
@inject('translationsStore', 'mypageStore', 'productsStore')
@observer
class ServicesView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      canMoVeInOptForRenewal: false,
      canMoVeInBeRenewed: false,
      checkingMoVeInRenewability: false,
      bannerDiscount: undefined,
      vehicleFromLombardy: false
    };
  }

  componentDidMount = () => {
    const { productsStore } = this.props;
    productsStore.loadAvailableProducts();
    productsStore.loadDiscountsRatePlans();
    productsStore.setOptionalService();

    disposeOnUnmount(
      this,
      reaction(
        () => this.props.mypageStore.selectedManageableVehicle,
        async vehicle => {
          if (vehicle) {
            await this.props.mypageStore.retrieveSelectedManageableVehicleDiscounts();

            const vehicleRegions = ((this.props.mypageStore.remoteUserVehiclesRegions.find(vehicle => vehicle.license_plate === this.props.mypageStore.selectedManageableVehicle.license_plate) || {}).regions) || [];
            this.setState({ vehicleFromLombardy: vehicleRegions.some(vr => vr.code === '03') }); //lombardy region code is 03

            this.checkAvailableBundle();
            this.setState({ checkingMoVeInRenewability: true });
            const { subscriptions } = vehicle;
            const {
              canMoVeInOptForRenewal,
              canMoVeInBeRenewed
            } = await subscriptions.filter(subscription => this.isMoveIn(subscription.product)).reduce(async (accumulator, subscription) => {
              const {
                canMoVeInOptForRenewal,
                canMoVeInBeRenewed,
              } = await this.checkMoVeInProductRenewability(
                subscription.product
              );
              return {
                canMoVeInOptForRenewal: canMoVeInOptForRenewal
                  ? true
                  : accumulator.canMoVeInOptForRenewal,
                canMoVeInBeRenewed: canMoVeInBeRenewed
                  ? true
                  : accumulator.canMoVeInBeRenewed,
              };
            }, { canMoVeInOptForRenewal: false, canMoVeInBeRenewed: false });
            this.setState({ canMoVeInOptForRenewal, canMoVeInBeRenewed, checkingMoVeInRenewability: false });
          }
        },
        { fireImmediately: true }
      )
    );
  };

  componentWillUnmount() {
    this.setState({ canMoVeInOptForRenewal: false, canMoVeInBeRenewed: false, bannerDiscount: undefined });
  }

  componentDidUpdate = async (prevProps, prevState) => {
    const products = await this.getProducts();

    if (!isEqual(prevState.products, products)) {
      this.setState({ products });

      this.checkAvailableBundle();
    }
  };

  onViewGroupChange = e => {
    const {
      dataCollection: {
        handlers: { goToKeyRoute }
      }
    } = this.props;
    goToKeyRoute(e.target.value);
  };

  handleProductSelection = product => {
    const { productsStore, history, mypageStore } = this.props;

    if (this.isAirRenewal(product)) {
      productsStore.selectAirRenewProduct(product);
      productsStore.setSelectedProductRateplan(null);
      productsStore.setSelectedAddOn(null)
      history.push('/air-renew');
      return;
    }

    if (this.isMoveInRenewal(product)) {
      productsStore.setSelectedProduct(product);
      productsStore.setSelectedProductRateplan(product.productRatePlans[0]);

      const vehicleHasAirClub = mypageStore.hasVehicleReminder(mypageStore.selectedManageableVehicle);
      const activeSubscriptionToAssistenzaBase = this.getCurrentSubscriptionForProductSKU('AssBase-MYG');
      const activeSubscriptionToMyAppAir = this.getCurrentSubscriptionForProductSKU('MYA-AIR');
      const vehicleHasAssistance = !!activeSubscriptionToAssistenzaBase;
      const vehicleHasMyAppAir = !!activeSubscriptionToMyAppAir;

      if (!vehicleHasAssistance) {
        history.push('/optional-services');
      } else if (!vehicleHasMyAppAir) {
        history.push('/addon-services');
      } else {
        history.push('/payments');
      }
      return;
    }
    if (this.isMyAppProduct(product)) {
      productsStore.setSelectedProduct(product);
      productsStore.setSelectedProductRateplan(null);
      productsStore.setComeFromServices(true)
      history.push('/addon-services');
    }

    productsStore.setSelectedProduct(product);
    if (this.isBlackBox(product)) {
      productsStore.setSelectedProductRateplan(product.productRatePlans[0]);
      if (this.isMoveIn(product)) {
        history.push('/movein/installer');
      } else {
        history.push('/optional-services');
      }
    }

    if (productsStore.isAssistance(product)) {
      productsStore.setSelectedProduct(product);
      productsStore.setSelectedProductRateplan(null);
      productsStore.setSelectedAddOn(null)
      history.push('/buy-assistance');
    }

    if (productsStore.isCarlLocator(product) || productsStore.isFindMechanic(product)) {
      productsStore.setSelectedProduct(product);
      productsStore.setSelectedProductRateplan(null);
      productsStore.setSelectedAddOn(null)
      history.push('/buy-additional-service');
    }
  };

  isDiscounted = product => {
    const { productsStore } = this.props;

    if (this.isAirRenewal(product)) {

      product = productsStore.availableProducts.find(product => product.category === 'renew' && product.features.some(feature => feature.node.name === 'air'));
    }

    return productsStore.getDiscountFromProductUuid(product && product.uuid);
  };

  checkAvailableBundle = () => {
    let bannerDiscount;

    for (const product of this.state.products) {

      const purchasableProductsUuids = !this.getActiveSubscriptionForProduct(product && product.uuid) && this.getPurchasableBundleProductsUuids(product);

      bannerDiscount = this.props.productsStore.getDiscountOnBundleWithProductUuids(purchasableProductsUuids);

      if (bannerDiscount) {
        break;
      }
    }

    this.setState({ bannerDiscount });
  };

  getPurchasableBundleProductsUuids = product => {
    const { productsStore, mypageStore } = this.props;

    const purchasableProductsUuids = [];

    const vehicleHasAirClub = mypageStore.hasVehicleReminder(mypageStore.selectedManageableVehicle);
    if (!vehicleHasAirClub) {
      const airClubProduct = productsStore.availableProducts.find(product => product.features.some(feature => feature.node.name === 'axa-assistance'));

      if (airClubProduct && !this.getActiveSubscriptionForProduct(airClubProduct.uuid)) {
        purchasableProductsUuids.push(airClubProduct && airClubProduct.uuid);
      }
    }

    if (this.isAirRenewal(product)) {

      const airRenewProduct = productsStore.availableProducts.find(product => product.category === 'renew' && product.features.some(feature => feature.node.name === 'air'));

      if (!this.getActiveSubscriptionForProduct(airRenewProduct.uuid)) {
        purchasableProductsUuids.push(airRenewProduct && airRenewProduct.uuid);
      }

      return purchasableProductsUuids;
    }

    if (!this.getActiveSubscriptionForProduct(product && product.uuid)) {
      purchasableProductsUuids.push(product && product.uuid);
    }

    return purchasableProductsUuids;
  };

  getActiveSubscriptionForProduct = productUuid => {
    const { mypageStore } = this.props;
    const subscription = !!mypageStore.selectedManageableVehicle.subscriptions && mypageStore.selectedManageableVehicle.subscriptions.find(sub => sub.product.uuid === productUuid);

    if (subscription) {
      const isExpiring = subscription && subscription.node && subscription.node.end_date && new Date(subscription.node.end_date) < addDays(new Date(), 30);

      if (!isExpiring) {
        return subscription;
      }
    }

    return undefined;
  };

  getCurrentSubscriptionForProductSKU = sku => {
    const { mypageStore } = this.props;
    const subscription = !!mypageStore.selectedManageableVehicle.subscriptions && mypageStore.selectedManageableVehicle.subscriptions.find(sub => sub.product.sku === sku);

    if (subscription) {
      const isExpiring = subscription && subscription.node && subscription.node.end_date && new Date(subscription.node.end_date) < new Date();

      if (!isExpiring) {
        return subscription;
      }
    }

    return undefined;
  };

  getProducts = async () => {
    const { mypageStore, productsStore } = this.props;

    if (!mypageStore.selectedManageableVehicle) {
      return [];
    }

    const { canMoVeInOptForRenewal, canMoVeInBeRenewed } = this.state;

    const products = mypageStore.remoteUserIsFromMoVeInProgram
      ? productsStore.availableProducts && productsStore.availableProducts.filter(prod => !this.isBlackBox(prod) || this.isMoveInBase(prod) || (this.isMoveInRenewal(prod) && productsStore.isBuyableProduct(prod) && (canMoVeInOptForRenewal || canMoVeInBeRenewed)) || this.isMoveInRenewalAndSubscribed(prod))
      : productsStore.availableProducts && productsStore.availableProducts.filter(prod => !this.isBlackBox(prod) || !this.isMoveIn(prod))
    return !this.hasBlackBoxSub()
      ? products.filter((product) => this.isBlackBox(product) && productsStore.isBuyableProduct(product))
      : this.filterProducts(products)
  };

  hasBlackBoxSub = () => {
    return this.props.mypageStore.selectedManageableVehicle.subscriptions.some(sub =>
      sub.product.features.some(feat => feat.node.name === 'black-box')
    )
  };

  isSubscribed = (product, subscriptions) => {
    return subscriptions.some(sub => sub.product.uuid === product.uuid);
  };

  hasSubscribedFeature = (product, subscriptions) => {
    const subscribedFeatures = subscriptions.reduce((acc, sub) => ([...acc, ...sub.product.features.map(f => f.node.uuid)]), []);
    return product.features.some(feature => subscribedFeatures.includes(feature.node.uuid));
  };

  filterProducts = async (products) => {
    const subscriptions = this.props.mypageStore.selectedManageableVehicle.subscriptions;

    const isExpired = this.hasExpiredBlackBoxProduct();

    const { canMoVeInOptForRenewal, canMoVeInBeRenewed } = this.state;


    const { mypageStore, productsStore } = this.props;
    const availableMoVeInRenewals = mypageStore.remoteUserIsFromMoVeInProgram
      ? productsStore.availableProducts.filter(prod => this.isMoveInRenewal(prod) && productsStore.isBuyableProduct(prod) && !canMoVeInOptForRenewal && canMoVeInBeRenewed) : [];
    const productsIncludingMoVeInRenewals = products.concat(availableMoVeInRenewals);

    let result = productsIncludingMoVeInRenewals;

    const purchasableAdditionalProducts = result.filter(product => productsStore.isPurchasableAdditionalProduct(product));

    result = result.filter(
      (product) =>
        this.isSubscribed(product, subscriptions) ||
        !this.hasSubscribedFeature(product, subscriptions) ||
        (this.isMoveInRenewal(product) && (canMoVeInOptForRenewal || canMoVeInBeRenewed))
    );
    result = result.filter((product) => (productsStore.areAirClubOrAssistanceFeaturesAlreadySubscribed(product) || (!this.isAddOn(product) && !isExpired)) && !productsStore.isPortability(product));

    return uniqBy(result.concat(purchasableAdditionalProducts), 'uuid');
  };

  isMoveIn = (product) => {
    return product.features.some(feature => feature.node.name === 'movein')
  };

  isMoveInBase = (product) => {
    return product.category === 'base' && product.features.some(feature => feature.node.name === 'movein')
  };

  isMoveInRenewal = (product) => {
    return product.category === 'renew' && product.features.some(feature => feature.node.name === 'movein')
  };

  isMyAppProduct = (product) => {
    return product.category === 'add-on' && product.features.some(feature => feature.node.name === 'myapp')
  };

  isMoveInRenewalAndSubscribed = (product) => {
    const { subscriptions = [] } = this.props.mypageStore.selectedManageableVehicle;
    return product.category === 'renew' && product.features.some(feature => feature.node.name === 'movein') && this.isSubscribed(product, subscriptions)
  };

  isBlackBox = (product) => {
    return product.features.some(feature => feature.node.name === 'black-box')
  };

  isAddOn = (product) => {
    return product.category === 'add-on';
  };

  isExpiring = (product) => {
    const subscription = this.props.mypageStore.selectedManageableVehicle.subscriptions.find(sub => sub.product.uuid === product.uuid);
    return subscription && subscription.node && new Date(subscription.node.end_date) < addDays(new Date(), 30);
  };

  hasExpiredBlackBoxProduct = () => {
    return this.props.mypageStore.selectedManageableVehicle
      .subscriptions
      .filter(sub => this.isBlackBox(sub.product))
      .every(sub => new Date(sub.node.end_date) < new Date());
  }

  isGiftProduct = (product) => {
    return product.category === 'gift';
  };

  isAirRenewal = product =>
    !this.isMoveIn(product)
    && this.isBlackBox(product)
    && !this.isGiftProduct(product)
    && this.isExpiring(product)

  checkMoVeInProductRenewability = async product => {
    let renewability = { canMoVeInOptForRenewal: false, canMoVeInBeRenewed: false };
    const {
      mypageStore: {
        selectedManageableVehicle: {
          subscriptions,
          id_adesione,
          previous_id_adesione
        }
      }
    } = this.props;
    if (!this.isMoveIn(product)) {
      return renewability;
    }
    const subscription = subscriptions.find(subscription => subscription.product.uuid === product.uuid);
    if (!(subscription && subscription.node)) {
      return renewability;
    }
    let { node: { end_date } } = subscription;
    if (!(new Date(end_date) < addDays(new Date(), 30))) {
      return renewability;
    }
    if (typeof previous_id_adesione !== 'undefined') {
      renewability = { canMoVeInOptForRenewal: false, canMoVeInBeRenewed: true };
      return renewability;
    }
    const moveinCodesHistory = await api('getHistoryFromIdAdesione', id_adesione)
      .catch(error => {
        console.error(error);
        return false;
      });

    //allowing renew users with A4 status received in last 60 days
    const hasRecentStatusA4 = moveinCodesHistory && moveinCodesHistory.find(code => code.operation_code === 'A4' && moment().diff(moment(code.received_on), 'days') < 60);

    renewability.canMoVeInOptForRenewal = !hasRecentStatusA4;
    renewability.canMoVeInBeRenewed = !!hasRecentStatusA4;
    return renewability;
  };

  render() {
    const { mypageStore, productsStore, translationsStore } = this.props;

    // get latest RC Auto insurance for selected vehicle
    const insurance = mypageStore.selectedVehicleRCAInsurance
    // insurance issued by fit2you and Active (expiring date > 60days)
    const isInsuranceFit2YouActive = insurance &&
      insurance.node.channel === 'fit2you' &&
      moment(insurance.node.date).isAfter(moment().add(60, 'days'));

    const isInsuranceExpiringNotMoreThan30Days = insurance &&
      moment(insurance.node.date).diff(moment(), 'days') < 60 &&
      moment(insurance.node.date).diff(moment(), 'days') > -30;
    // has selected vehicle "brand" property
    const selectedVehicleHasBrand = mypageStore.selectedManageableVehicle && mypageStore.selectedManageableVehicle.brand && Object.keys(mypageStore.selectedManageableVehicle.brand).length > 0;

    const viewGroupItems = [
      { value: 'vehicles', title: translationsStore.translate('groupViews.myair.vehicles') },
      { value: 'documents', title: translationsStore.translate('groupViews.myair.documents') },
      // { value: 'assets', title: translationsStore.translate('groupViews.myair.assets') },
      { value: 'services', title: translationsStore.translate('groupViews.myair.services') }
    ];
    const defaultGroupItem = 'services';

    const { products, canMoVeInOptForRenewal, canMoVeInBeRenewed, bannerDiscount, vehicleFromLombardy } = this.state;

    return (
      <MyPageContainer className='ServicesView' dataCollection={this.props.dataCollection}>
        <LoadingOverlay
          active={productsStore.loadingAvailableProducts || this.state.checkingMoVeInRenewability || mypageStore.loadingDiscounts || mypageStore.loadingManageableVehicles || mypageStore.loadingAgenda}
          spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true}/>}
          styles={{
            overlay: base => ({
              ...base,
              background: 'var(--grey-light-color-50)'
            })
          }}
        >
          <VehicleSelector/>

          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              const inputProps = breakpoints[currentBreakpoint] >= breakpoints.desktop ? { size: "large" } : {};
              return (
                <MyPageRadioGroup
                  {...inputProps}
                  className="SubPageNavbar"
                  defaultValue={defaultGroupItem}
                  onChange={this.onViewGroupChange}
                  buttonStyle="solid"
                >
                  {viewGroupItems.map((item, index) => <MyPageRadioButton key={index}
                                                                          value={item.value}>{item.title}</MyPageRadioButton>)}
                </MyPageRadioGroup>
              )
            }}
          </BreakpointsMedia>

          {canMoVeInOptForRenewal &&
            <div style={{ marginBottom: bannerDiscount ? 3 : 30 }} className="BannerMoVeInRenewal"
                 dangerouslySetInnerHTML={{ __html: translationsStore.translate('servicesView.renewals.movein.noticeToCustomer') }}/>}

          {bannerDiscount && <BannerBundleDiscount bannerDiscount={bannerDiscount}/>}

          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              return (mypageStore.remoteUserIsFromMoVeInProgram && selectedVehicleHasBrand && insurance && isInsuranceExpiringNotMoreThan30Days &&
                <InsuranceBanner
                  insurance={insurance}
                  size={breakpoints[currentBreakpoint] >= breakpoints.large ? "desktop" : "mobile"}/>)
            }}
          </BreakpointsMedia>

          <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-between" }}>
            {mypageStore.remoteUserIsFromMoVeInProgram && isInsuranceFit2YouActive &&
              <InsuranceCard insurance={insurance}/>}
            {
              products
                .map((product, index) =>
                  <ProductCardBase
                    key={index}
                    subscriptions={mypageStore.selectedManageableVehicle.subscriptions}
                    onSelect={this.handleProductSelection}
                    renewDisabled={
                      (this.isExpiring(product) && !(this.isAirRenewal(product) || this.isMoveInRenewal(product))) ||
                      (productsStore.isRenewSubscribed && (!productsStore.isTheMostRecentRenewExpiring || canMoVeInOptForRenewal)) ||
                      (productsStore.isRenewSubscribed && this.isAirRenewal(product)) // INFO: air renew should not be renewable by the user a second time since there is auto-renew done with recurring
                    }
                    product={product}
                    disabled={this.isMoveInRenewal(product) && canMoVeInOptForRenewal}
                    description={this.isMoveInRenewal(product) ? <span style={{
                      display: 'block',
                      textAlign: 'center',
                      fontWeight: 'bold'
                    }}>{translationsStore.translate('servicesView.renewals.movein.description')}</span> : product.description}
                    isDiscounted={this.isDiscounted(product)}
                    isBuyable={productsStore.isBuyableProduct(product)}
                  />
                )
            }
          </div>
        </LoadingOverlay>

        <Footer/>
      </MyPageContainer>
    );
  }
}

export default ServicesView;
