import { auth, movein, mypage } from '.';

//Rollbar is a global variable, lint complains
//https://github.com/facebook/create-react-app/blob/master/packages/react-scripts/template/README.md#using-global-variables
//const Rollbar = window.Rollbar;

const api = (action, ...params) => {
  const apis = {
    login: auth.login,
    currentUser: auth.currentUserPoolUser,
    currentSession: auth.currentSession,
    completeNewPassword: auth.completeNewPassword,
    changePassword: auth.changePassword,
    forgotPassword: auth.forgotPassword,
    forgotPasswordSubmit: auth.forgotPasswordSubmit,
    getHistoryFromIdAdesione: movein.getHistoryFromIdAdesione,
    getUserData: mypage.getUserData,
    updateUserProfile: mypage.updateUserProfile,
    getUserManageableVehicles: mypage.getUserManageableVehicles,
    getUserManageableVehicleData: mypage.getUserManageableVehicleData,
    getZuoraHostedPageToken: mypage.getZuoraHostedPageToken,
    setPaymentMethodDefault: mypage.setPaymentMethodDefault,
    subscribeToProduct: mypage.subscribeToProduct,
    getSlimpayIframe: mypage.getSlimpayIframe,
    getSlimpayOrder: mypage.getSlimpayOrder,
    getInstallersByProvince: movein.getInstallersByProvince,
    chooseInstaller: movein.chooseInstaller,
    getProducts: mypage.getProducts,
    getDocuments: mypage.getDocuments,
    downloadDocument: mypage.downloadDocument,
    downloadInvoice: mypage.downloadInvoice,
    updateMarketingConsent: mypage.updateMarketingConsent,
    getContractsGeneralConditionsName: mypage.getContractsGeneralConditionsName,
    updateDrivingLicense: mypage.updateDrivingLicense,
    createDrivingLicense: mypage.createDrivingLicense,
    getDrivingLicense: mypage.getDrivingLicense,
    updateAirclubMarketingConsent: mypage.updateAirclubMarketingConsent,
    getAgendaInsurances: mypage.getAgendaInsurances,
    getAgendaMaintenances: mypage.getAgendaMaintenances,
    getAgendaTaxes: mypage.getAgendaTaxes,
    createAgendaMaintenance: mypage.createAgendaMaintenance,
    createAgendaTaxes: mypage.createAgendaTaxes,
    createAgendaInsurance: mypage.createAgendaInsurance,
    updateAgendaMaintenance: mypage.updateAgendaMaintenance,
    updateAgendaTaxes: mypage.updateAgendaTaxes,
    updateAgendaInsurance: mypage.updateAgendaInsurance,
    getAgendaInsurancePrice: mypage.getAgendaInsurancePrice,
    getAgendaInsuranceToken: mypage.getAgendaInsuranceToken,
    updateAirCustomerConsent: mypage.updateAirCustomerConsent,
    uploadDocument: mypage.uploadDocument,
    updateDocument: mypage.updateDocument,
    deleteDocument: mypage.deleteDocument,
    getIdentityCard: mypage.getIdentityCard,
    updateIdentityCard: mypage.updateIdentityCard,
    createIdentityCard: mypage.createIdentityCard,
    updateVehicleData: mypage.updateVehicleData,
    getNotification: mypage.getNotification,
    getArchivedNotifications: mypage.getArchivedNotifications,
    archiveNotification: mypage.archiveNotification,
    snoozeNotification: mypage.snoozeNotification,
    confirmSupportRequestNotification: mypage.confirmSupportRequestNotification,
    getZuoraPaymentMethods: mypage.getZuoraPaymentMethods,
    getZuoraToken: mypage.getZuoraToken,
    getAvailableDiscounts: mypage.getAvailableDiscounts,
    getDiscountRatePlans: mypage.getDiscountRatePlans
  };

  try {
    return apis[action](...params);
  } catch (error) {
    console.error(`action: ${action} - error: ${error}`);
    //Rollbar.error(error);
    return Promise.reject(Object.assign({}, error, { severity: 'high' }));
  }
};

export default api;
