import React, { Component } from 'react';
import classNames from 'classnames';
import { Button } from 'antd';

import './MyPageButton.css';

export default class MyPageButton extends Component {
  render() {
    const cls = classNames({
      MyPageButton: true,
      disabled: !!this.props.disabled,
      [this.props.className]: !!this.props.className
    });
    const { title, theme } = this.props;
    let outerStyle = {};
    let innerStyle = {};
    if (this.props.hasOwnProperty('outlined')) {
      outerStyle = { ...outerStyle, borderRadius: 8, border: '1px solid var(--grey-semi-color)' };
    }
    if (this.props.hasOwnProperty('solid')) {
      let thBackgroundColor = '';
      if (this.props.hasOwnProperty('theme')) {
        thBackgroundColor = !this.props.disabled ? theme === 'salmon' ? 'var(--air-salmon-color)' : `var(--action-${theme}-color)` : `var(--light-${theme}-color)`;
      } else {
        thBackgroundColor = !this.props.disabled ? 'var(--action-custom-color)' : 'var(--light-custom-color)';
      }
      outerStyle = { ...outerStyle, borderRadius: 8, backgroundColor: thBackgroundColor, color: 'white' };
    }
    if (this.props.hasOwnProperty('iconLeft')) {
      innerStyle = { ...innerStyle, marginLeft: 12 };
    }
    if (this.props.hasOwnProperty('iconRight')) {
      innerStyle = { ...innerStyle, marginRight: 12 };
    }
    outerStyle = { ...outerStyle, height: 32, ...this.props.style };
    return (
      <Button className={cls} style={outerStyle} htmlType={this.props.htmlType} disabled={this.props.disabled} onClick={this.props.onClick}>
        <div className={'MyPageButtonInner'}>
          {this.props.hasOwnProperty('iconLeft') && this.props.iconLeft}
          <div style={innerStyle}>{title}</div>
          {this.props.hasOwnProperty('iconRight') && this.props.iconRight}
        </div>
      </Button>
    );
  }
}
