import React, { Component } from 'react';
import './InsuranceBanner.css'
import './Loader.css'
import MyPageButton from "../MyPageButton";
import AppBrand from "../AppBrand";
import Elephant from "../AppBrand/Elephant";
import { inject, observer } from "mobx-react";
import { format } from "date-fns";
import { api } from "../../../services";
import K from '../../../constants'
import moment from 'moment'
import { message } from 'antd'

const insuranceNameImgMapper = {
  air: 'air.webp',
  allianz: 'allianz.webp',
  argo: 'argo.webp',
  assurant: 'assurant.webp',
  axa: 'axa.webp',
  conte: 'conte.webp',
  default: 'default.webp',
  fit2you: 'fit2you.webp',
  ima: 'ima.webp',
  nobis: 'nobis.webp',
  prima: 'prima.webp',
  quixa: 'quixa.webp',
  revo: 'revo.webp',
  tua: 'tua.webp',
  uca: 'uca.webp',
  verti: 'verti.webp',
  vittoria: 'vittoria.webp',
  zurich: 'zurich.webp',
}

@inject('translationsStore', 'mypageStore', 'authStore')
@observer
export default class InsuranceBanner extends Component {
  dummyPrice = 159
  pollingIntervalTime = 10000;
  maxPollingMinutes = 4;

  constructor(props) {
    super(props);
    this.state = {
      fetchedDateTime: undefined,
      requestedDateTime: undefined,
      price: props.price,
      discountExpirationDate: undefined,
      discountPrice: undefined,
      priceExpiration: undefined,
      waitingForPrice: props.waitingForPrice === undefined ? true : props.waitingForPrice,
      companyName: undefined,
      loadingToken: false,
    };
  }

  fetchInsurancePrice = async (userUuid, vehicleId) => {
    try {
      const data = await api('getAgendaInsurancePrice', userUuid, vehicleId);
      this.setState(prev => ({
        ...data,
        companyName: data.companyName ? data.companyName.split(' ')[0].toLowerCase() : undefined,
        loadingToken: prev.loadingToken
      }));
    } catch (error) {
      this.setState({
        waitingForPrice: false,
      })
      console.error(error)
    }
  };

  startPollingPrice = () => {
    const { mypageStore, authStore, insurance } = this.props;
    const {
      attributes: { 'custom:external_uuid': userUuid }
    } = authStore.user ? authStore.user : {};

    if (this.pollingInterval) {
      clearInterval(this.pollingInterval);
    }

    const shouldPollPrice = () => {
      // insurance Expiring (expiring date <= 60 days) or Expired (expiring date < now)
      const isInsuranceExpiringOrExpired = insurance && moment(insurance.node.date).isBefore(moment().add(60, 'days'));
      const selectedVehicle = mypageStore.selectedManageableVehicle;

      if (isInsuranceExpiringOrExpired && selectedVehicle && selectedVehicle.marketing_third_parties_consent_accepted) {
        const priceExpirationDifference = moment.duration(moment().diff(moment(this.state.priceExpiration)))
        return (this.state.priceExpiration === undefined || priceExpirationDifference.hours() > 0 || priceExpirationDifference.minutes() > 0 || priceExpirationDifference.seconds() > 0)
      }

      return false
    };

    if (shouldPollPrice()) {
      this.fetchInsurancePrice(userUuid, mypageStore.selectedManageableVehicle.uuid);
    }

    this.pollingInterval = setInterval(() => {
      // condition for when to fetch price
      const difference = this.state.requestedDateTime ?
        moment.duration(moment().diff(moment(this.state.requestedDateTime))) : moment.duration(0);

      if (difference.hours() > 0 || difference.minutes() >= this.maxPollingMinutes) {
        clearInterval(this.pollingInterval);
        return;
      }

      if (this.state.waitingForPrice === false) {
        clearInterval(this.pollingInterval);
        return;
      }

      if (shouldPollPrice()) {
        this.fetchInsurancePrice(userUuid, mypageStore.selectedManageableVehicle.uuid)
      } else {
        clearInterval(this.pollingInterval);
      }
    }, this.pollingIntervalTime);
  };

  componentDidMount() {
    this.startPollingPrice();
  }

  componentDidUpdate(prevProps) {
    // Only restart polling if relevant props have changed
    const shouldRestart =
      prevProps.insurance !== this.props.insurance ||
      prevProps.mypageStore.selectedManageableVehicle !== this.props.mypageStore.selectedManageableVehicle;

    if (shouldRestart) {
      this.startPollingPrice();
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollingInterval);
  }

  render() {
    const { mypageStore, authStore, translationsStore, size } = this.props;

    // get latest RC Auto insurance for selected vehicle
    const insurance = mypageStore.selectedVehicleRCAInsurance
    // insurance not issued by fit2you and active (expiring date > 60 days)
    const isInsuranceNotFit2YouActive = insurance &&
      insurance.node.channel !== 'fit2you' &&
      moment(insurance.node.date).isAfter(moment().add(60, 'days'));
    // insurance issued by fit2you and Expiring (expiring date <= 60 days)
    const isInsuranceFit2YouExpiring = insurance &&
      insurance.node.channel === 'fit2you' &&
      moment(insurance.node.date).isBetween(moment(), moment().add(60, 'days'));

    let definitivePrice = this.state.price
    if (this.state.price && this.state.discountExpirationDate && this.state.discountPrice) {
      moment().isBefore(moment(this.state.discountExpirationDate)) && (definitivePrice = this.state.discountPrice)
    }

    let topSectionRCAuto = undefined
    let bottomSectionRCAuto = undefined
    let title = translationsStore.translate('InsuranceBanner.title.offer')
    let button = <MyPageButton theme="salmon" solid
                               title={isInsuranceFit2YouExpiring ? translationsStore.translate('InsuranceBanner.button.renew') : translationsStore.translate('InsuranceBanner.button.show')}
                               style={{ width: 184, height: 39 }}
                               onClick={async () => {
                                 const {
                                   attributes: { 'custom:external_uuid': userUuid }
                                 } = authStore.user ? authStore.user : {};

                                 try {
                                   this.setState(prev => ({ ...prev, loadingToken: true }));
                                   const data = await api('getAgendaInsuranceToken', userUuid, mypageStore.selectedManageableVehicle.uuid);
                                   if (data.token === undefined) {
                                     throw new Error('token is undefined')
                                   }
                                   window.location.href = `${K.FIT2YOU_INSURANCE_OFFER_URL}/guest-login?token=${data.token}`
                                 } catch (error) {
                                   message.error(translationsStore.translate('InsuranceBanner.error.message'))
                                   console.error(error)
                                 } finally {
                                   this.setState(prev => ({ ...prev, loadingToken: false }));
                                 }
                               }}
    />

    if (!insurance || isInsuranceNotFit2YouActive) {
      title = translationsStore.translate('InsuranceBanner.title.news');
      topSectionRCAuto = <span>{translationsStore.translate('InsuranceBanner.sectionComeBack.top1')} <span
        style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionComeBack.top2')}</span></span>

      bottomSectionRCAuto =
        <span style={{ lineHeight: 1 }}>{translationsStore.translate('InsuranceBanner.sectionComeBack.bottom')}</span>
      button = undefined
    } else if (isInsuranceFit2YouExpiring) {
      topSectionRCAuto =
        <span style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionExpiring.top')}</span>

      bottomSectionRCAuto =
        <span><span
          className='Label'>{translationsStore.translate('InsuranceBanner.sectionExpiring.bottom1')}</span> &nbsp; {translationsStore.translate('InsuranceBanner.sectionExpiring.bottom2')} {format(insurance.node.date, "DD/MM/YYYY")}
        </span>
    } else if (this.state.waitingForPrice) {
      topSectionRCAuto =
        <span style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionDummyPrice.top')} <span
          style={{ fontSize: 20 }}>{this.dummyPrice}€</span></span>

      bottomSectionRCAuto = <span>{translationsStore.translate('InsuranceBanner.sectionDummyPrice.bottom1')} <span
        style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionDummyPrice.bottom2')} {this.dummyPrice}€</span>
      </span>
    } else if (definitivePrice) {
      topSectionRCAuto =
        <span style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionExactPrice.top')} <span
          style={{ fontSize: 20 }}>{definitivePrice}€</span></span>

      bottomSectionRCAuto = <span>{translationsStore.translate('InsuranceBanner.sectionExactPrice.bottom1')} <span
        style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionExactPrice.bottom2')} {definitivePrice}€</span>
      </span>
    } else {
      topSectionRCAuto =
        <span style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionDummyPrice.top')} <span
          style={{ fontSize: 20 }}>{this.dummyPrice}€</span></span>

      bottomSectionRCAuto = <span>{translationsStore.translate('InsuranceBanner.sectionDummyPrice.bottom1')} <span
        style={{ fontWeight: 700 }}>{translationsStore.translate('InsuranceBanner.sectionDummyPrice.bottom2')} {this.dummyPrice}€</span>
      </span>
    }

    if (size === 'mobile') {
      return <div style={{
        minHeight: 207
      }} className={'InsuranceBannerContainer'}>
        <div className={'InsuranceBannerMainSectionMobile'}>
          <div className={'InsuranceBannerLogo'}>
            <AppBrand style={{ width: 33, height: 19, color: 'var(--action-brand-color)' }}/>
            <Elephant style={{ width: 22, height: 28 }}/>
          </div>

          <div className={'InsuranceBannerTitle'} style={{ width: 126 }}>{title}</div>

          <img alt={`${this.state.companyName ? this.state.companyName : 'default'} logo`}
               src={`insurance/${insuranceNameImgMapper[this.state.companyName] ? insuranceNameImgMapper[this.state.companyName] : insuranceNameImgMapper.default}`}
               style={{ marginTop: 18, marginLeft: 22 }}
               className={'InsuranceBannerImg'}/>
        </div>

        <div style={{ marginLeft: 16, marginBottom: 11 }}>
          {topSectionRCAuto}
          <br/>
          {bottomSectionRCAuto}
        </div>

        {this.state.loadingToken ? <div className="loader"/> : button}
      </div>
    }

    return <div className={'InsuranceBannerContainer'}>
      <div className={'InsuranceBannerTitle'}>{title}</div>
      <div className={'InsuranceBannerMainSection'}>
        <div className={'InsuranceBannerInnerSection'}>
          <img alt={`${this.state.companyName ? this.state.companyName : 'default'} logo`}
               src={`insurance/${insuranceNameImgMapper[this.state.companyName] ? insuranceNameImgMapper[this.state.companyName] : insuranceNameImgMapper.default}`}
               className={'InsuranceBannerImg'}/>
        </div>

        <div style={{ marginTop: isInsuranceNotFit2YouActive || isInsuranceFit2YouExpiring ? 0 : -14 }}
             className={'InsuranceBannerInnerSection RCAutoSection'}>
          {topSectionRCAuto}
          <br/>
          {bottomSectionRCAuto}
        </div>
        <div className={'InsuranceBannerInnerSection'}>
          {this.state.loadingToken ? <div className="loader"/> : button}
        </div>
        <div className={'InsuranceBannerLogo'}>
          <AppBrand style={{ width: 33, height: 19, color: 'var(--action-brand-color)' }}/>
          <Elephant style={{ width: 22, height: 28 }}/>
        </div>
      </div>
    </div>
  }
}
