import React from 'react';

const SvgOther78 = ({ svgRef, title, ...props }) => (
  <svg viewBox="0 0 512 512" ref={svgRef}  {...{ ...{ width: '1em', height: '1em' }, ...props }}>
    <title>{title}</title>
    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
       fill="#000000" stroke="none">
      <path
        d="M1575 5113 c-75 -7 -200 -35 -285 -65 -426 -150 -742 -508 -837 -948 -27 -127 -25 -411 4 -537 89 -379 335 -693 678 -866 490 -246 1083 -150 1471 237 248 248 377 557 377 906 0 349 -129 657 -378 906 -268 269 -642 402 -1030 367z"/>
      <path
        d="M3513 2770 c-226 -76 -435 -151 -464 -166 -116 -63 -207 -175 -250 -305 l-24 -74 0 -470 c0 -517 3 -545 66 -718 133 -367 486 -719 963 -963 l132 -68 85 34 c509 201 879 541 1024 940 64 178 68 211 72 718 3 324 1 484 -7 532 -24 140 -117 279 -239 358 -39 25 -174 75 -485 178 -237 79 -438 144 -446 143 -8 0 -200 -63 -427 -139z"/>
      <path
        d="M852 2109 c-376 -79 -680 -349 -796 -709 -50 -152 -49 -144 -53 -787 l-4 -613 1563 0 1563 0 -40 32 c-329 269 -561 586 -663 908 -65 205 -65 202 -69 718 l-4 472 -702 -1 c-656 0 -708 -2 -795 -20z"/>
    </g>
  </svg>);

const ForwardRef = React.forwardRef((props, ref) => <SvgOther78 svgRef={ref} {...props} />);
export default ForwardRef;
