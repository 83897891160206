import React, { Component } from "react";
import './AdvertisingRCABanner.css'
import { inject } from "mobx-react";

@inject('translationsStore')
export default class AdvertisingRCABanner extends Component {
  constructor(props) {
    super(props);
  }

  render() {

    const { size, translationsStore, isInsuranceFit2YouActive } = this.props;

    if (isInsuranceFit2YouActive) {
      return (
        <div className={"AdvertisingRCABanner-container"} style={{ width: size === "mobile" ? "100%" : 311 }}>
          <p style={{ fontWeight: 700, fontSize: 20 }}>
            💡 {translationsStore.translate('AdvertisingRCABanner.section3.title')}
          </p>

          <p>{translationsStore.translate('AdvertisingRCABanner.section3.description')}</p>
        </div>
      )
    }

    return (
      <div className={"AdvertisingRCABanner-container"} style={{ width: size === "mobile" ? "100%" : 311 }}>
        <p style={{ fontWeight: 700, fontSize: 20 }}>
          💡 {translationsStore.translate('AdvertisingRCABanner.section1.title')}
        </p>

        <p>
          {translationsStore.translate('AdvertisingRCABanner.section1.description1')} <span
          style={{
            fontWeight: 700,
            fontSize: 14
          }}> {translationsStore.translate('AdvertisingRCABanner.section1.description2')} </span>
          {translationsStore.translate('AdvertisingRCABanner.section1.description3')}<span
          style={{
            fontWeight: 700,
            fontSize: 14
          }}> {translationsStore.translate('AdvertisingRCABanner.section1.description4')}</span>
          {translationsStore.translate('AdvertisingRCABanner.section1.description5')} 🚦
        </p>

        <br/>

        <p style={{ fontWeight: 700, fontSize: 20 }}>
          {translationsStore.translate('AdvertisingRCABanner.section2.title')}
        </p>

        <p>
          {translationsStore.translate('AdvertisingRCABanner.section2.description1')} <span style={{
          fontWeight: 700,
          fontSize: 14
        }}>{translationsStore.translate('AdvertisingRCABanner.section2.description2')} </span>
          {translationsStore.translate('AdvertisingRCABanner.section2.description3')}
        </p>
      </div>
    )
  }
}
