import { withRouter } from "react-router-dom";
import { inject, observer } from "mobx-react";
import React, { Component, Fragment } from "react";

import './InsuranceView.css'
import MyPageContainer from "../../../../common/components/MyPageContainer";
import { PulseLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import VehicleSelector from "../../../../common/components/VehicleSelector";
import InsuranceBanner from "../../../../common/components/InsuranceBanner";
import InsuranceCard from "../../../../common/components/ProductCard/InsuranceCard";
import moment from 'moment'
import AdvertisingRCABanner from "./AdvertisingRCABanner";
import Footer from "../../../../common/components/Footer";
import { Media as BreakpointsMedia } from "react-breakpoints";


@withRouter
@inject('mypageStore')
@observer
export default class InsuranceView extends Component {
  async componentDidMount() {
  }

  render() {
    const { dataCollection, mypageStore } = this.props;

    // get latest RC Auto insurance for selected vehicle
    const insurance = mypageStore.selectedVehicleRCAInsurance
    // insurance issued by fit2you and Active (expiring date > 60days)
    const isInsuranceFit2YouActive = insurance &&
      insurance.node.channel === 'fit2you' &&
      moment(insurance.node.date).isAfter(moment().add(60, 'days'));

      const isInsuranceExpiringNotMoreThan30Days = insurance &&
        moment(insurance.node.date).diff(moment(), 'days') < 60 &&
        moment(insurance.node.date).diff(moment(), 'days') > -30;
    // has selected vehicle "brand" property
    const selectedVehicleHasBrand = mypageStore.selectedManageableVehicle && mypageStore.selectedManageableVehicle.brand && Object.keys(mypageStore.selectedManageableVehicle.brand).length > 0;

    return (<Fragment>
      <MyPageContainer dataCollection={dataCollection}>
        <LoadingOverlay
          active={mypageStore.loadingManageableVehicles || mypageStore.loadingAgenda}
          spinner={<PulseLoader sizeUnit={'px'} size={10} color={'var(--action-primary-color)'} loading={true}/>}
          styles={{
            overlay: base => ({
              ...base, background: 'var(--grey-light-color-50)'
            })
          }}
        >
          <VehicleSelector/>

          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              return (mypageStore.remoteUserIsFromMoVeInProgram && selectedVehicleHasBrand && insurance && isInsuranceExpiringNotMoreThan30Days &&
                <div style={{
                  display: "flex",
                  flexDirection: breakpoints[currentBreakpoint] >= breakpoints.large ? "row" : "column",
                  justifyContent: "space-between",
                  gap: 40,
                }}>
                  <div>
                    <InsuranceBanner
                      insurance={insurance}
                      size={breakpoints[currentBreakpoint] >= breakpoints.large ? "desktop" : "mobile"}/>
                  </div>
                  <AdvertisingRCABanner
                    size={breakpoints[currentBreakpoint] >= breakpoints.large ? "desktop" : "mobile"}/>
                </div>
              )
            }}
          </BreakpointsMedia>


          <BreakpointsMedia>
            {({ breakpoints, currentBreakpoint }) => {
              return (mypageStore.remoteUserIsFromMoVeInProgram && isInsuranceFit2YouActive &&
                <div style={{
                  display: "flex",
                  flexDirection: breakpoints[currentBreakpoint] >= breakpoints.large ? "row" : "column",
                  justifyContent: breakpoints[currentBreakpoint] >= breakpoints.large ? "space-between" : "flex-start",
                  alignItems: breakpoints[currentBreakpoint] >= breakpoints.large ? "flex-start" : "center"
                }}>
                  <div>
                    <InsuranceCard insurance={insurance}/>
                  </div>
                  <div style={{ marginTop: 16 }}>
                    <AdvertisingRCABanner isInsuranceFit2YouActive={isInsuranceFit2YouActive}/>
                  </div>
                </div>
              )
            }}
          </BreakpointsMedia>

        </LoadingOverlay>

        <Footer/>
      </MyPageContainer>
    </Fragment>)
  }
}
